console.log("Javascript running");
let x: number = 1;

let main = document.getElementById("main");
main.innerHTML = "<div>Konge, dette funker!!</div>";
const url = "ws://localhost:3123/ws/1"
let ws = new WebSocket(url);

ws.onopen = () => {
    ws.send("Kult1")
}

ws.onclose = () => {
    ws.send("Bye!")
}

ws.onmessage = (event) => {
    console.log(event.data);
    main.innerHTML = "<div>" + event.data + "</div>";
}

main.onclick = () => {
    ws.send("Knapp trykket");
}